import { render, staticRenderFns } from "./GoodTableBasic.vue?vue&type=template&id=cece5646&scoped=true&"
import script from "./GoodTableBasic.vue?vue&type=script&lang=js&"
export * from "./GoodTableBasic.vue?vue&type=script&lang=js&"
import style0 from "./GoodTableBasic.vue?vue&type=style&index=0&id=cece5646&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cece5646",
  null
  
)

export default component.exports